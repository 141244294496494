import Vue from 'vue';

export default Vue.extend({
    methods: {
        mixinDate(date: string): string {

            if (date) {

                let returnDate = date;

                const localDate = date.split('T');

                let dateArr;

                if (localDate.length === 2) {
                    dateArr = localDate[0].split('-');
                } else {
                    dateArr = date.split('-');
                }

                if (dateArr.length === 3) {
                    returnDate = `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
                }

                return returnDate;
            } else {
                return 'Invalid Date';
            }
        },
        numberFilter($event: any, onlyNum: boolean) {
            const keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            if (!onlyNum && keyCode === 46 && $event.target.value.split('.').length > 1) {
                $event.preventDefault();
            }

            if ((keyCode < 48 || keyCode > 57) && (onlyNum || (!onlyNum && keyCode !== 46))) {
                $event.preventDefault();
            }
        }
    }
});
