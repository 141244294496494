import api from '@/api/axiosFactory';
import store from '@/store';

export default function(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
        api({
            url: `/api/rpc/user_external`,
            method: 'post',
            data: {
                jsonrpc: '2.0',
                ...data
            }
        }).then((response: any) => {
            if (response.data.error && response.data.error.message) {
                const show = response.data.error.code !== 401 && response.data.error.code !== -32001;

                if (!show) {
                    store.commit('user/openAuth');
                }

                reject({
                    code: response.data.error.code,
                    message: response.data.error.message,
                    show
                });
            }

            resolve(response.data.result);
        }).catch((e: any) => {
            console.log(e);

            reject({
                code: 0,
                message: 'Отсутствует подключение к интернету',
                show: true
            });
        });
    });
}
