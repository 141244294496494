import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
        meta: {
            title: 'Авторизация'
        }
    },
    {
        path: '/postback',
        name: 'postback',
        component: () => import(/* webpackChunkName: "postback" */ '../views/Events.vue'),
        meta: {
            title: 'Отчет по постбэкам'
        }
    },
    {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/Partners.vue'),
        meta: {
            title: 'Партнеры'
        }
    }
];

const router = new VueRouter({
  routes
});

export {
    routes,
    router
};
