import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import breadcrumbs from './modules/breadcrumbs';
import uniq from './modules/uniq';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        breadcrumbs,
        uniq
    },
    strict: true
});
